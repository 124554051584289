import * as React from 'react'
import { Container } from 'reactstrap'
import Layout from '@components/Layout'
import Meta from '../components/meta/HomeMeta'

const NotFoundPage = () => (
  <Layout>
    <Container className="my-5 py-5 w-100 mx-auto">
      <Meta title="Page Not Found | aurasjobs.ro" />
      <h1 className="mt-5">404: Page Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
